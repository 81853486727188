import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const SectionBannerImage = styled(
  ({
    className,
    margin,
    children,
    title,
    image,
    bgImage,
    alt,
    topBanner,
    description,
    gradient
  }) => {
    const bgImageStyles = {
      background: gradient ? `linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 140%),url(${bgImage})` : `url(${bgImage})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }
    return (
      <div style={bgImage ? bgImageStyles : null} className={className}>
        {image && !topBanner ? (
          <GatsbyImage alt={alt ? alt : "Yacht pic"} image={image} />
        ) : (
          children
        )}
        {/*<h2>{title ? title : "Yacht for drive"}</h2>*/}
        {(title || description) && (
          <div className="main-info">
            {title ? <h2>{title}</h2> : null}
            {description ? <p>{description}</p> : null}
          </div>
        )}
      </div>
    )
  }
)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: ${({ topBanner }) => (topBanner ? "95%" : "100%")};
  margin: ${({ topBanner, margin }) =>
    topBanner ? "0 auto" : "30px auto 0 auto"};
  margin-top: ${({ noMargin }) => (noMargin ? "0px" : "30px")};
  height: ${({ topBanner }) => (topBanner ? "calc(100vh - 125px)" : "initial")};
  //padding: ${({ topBanner }) => (topBanner ? "0 50px" : "initial")};
  color: white;
  .main-info {
    position:relative;
    bottom:10px;
  }
  h2 {
    position:relative;
    width: 100%;
    font-family: "Tenor Sans",sans-serif;
    font-size: calc(24px + (60 - 12) * ((100vw - 300px) / (1600 - 300)));
    text-transform: uppercase;
    font-weight: 400;
    color: white;
    text-align: center;
  }
  p {
    text-align: center;
  }
`

export default SectionBannerImage
