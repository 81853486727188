const isEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}

const localizedValue = (locales, lang) => {
  return locales.find((loc) => loc.iso === lang).value
}

const getSection = (sections, sectionName) => {
  return sections.find((section) => section.keyPrefix === sectionName)
}

const getSections = (sections) => {
  let temp = {}
  sections.forEach((section) => {
    const sectionName = section.keyPrefix
    // temp = {...temp, [sectionName]: section.items}
    temp = { ...temp, [sectionName]: { items: section.items } }
    if (section.selectedModules?.length > 0) {
      temp = {
        ...temp,
        [sectionName]: {
          ...temp[sectionName],
          selectedModules: section.selectedModules,
        },
      }
    }
    if (section.selectedPlugin) {
      temp = {
        ...temp,
        [sectionName]: {
          ...temp[sectionName],
          selectedPlugin: section.selectedPlugin,
        },
      }
    }
  })
  return temp
}

const findObjPropAndReplace = (obj, property) => {
  let resObj = {}
  Object.keys(obj).forEach((key) => {
    if (key === property) {
      const locs = obj[key].reduce((acc, current) => {
        for (let val in current) {
          if (val !== "iso") {
            acc = {
              ...acc,
              [val]: { ...acc[val], [current.iso]: current[val] },
            }
          }
        }
        return acc
      }, {})
      resObj = { ...obj, [key]: locs }
    }
    if (typeof obj[key] === "object" && key !== property) {
      let res = findObjPropAndReplace(obj[key], property)
      if (!isEmpty(res)) {
        resObj = { ...resObj, [key]: { ...resObj[key], ...res } }
      }
    }
  })
  return resObj
}

const getItems = (items) => {
  return items.reduce((acc, current) => {
    if (current["__typename"] === "DataItemTextGet") {
      let loc = current.locales.reduce((a, c) => {
        return (a = { ...a, [c.iso]: c.value })
      }, {})
      acc = { ...acc, [current.key]: loc }
    }
    if (current["__typename"] === "DataItemAssetGet") {
      let loc = current.value.locales.reduce((a, c) => {
        for (let val in c) {
          if (val !== "iso") {
            a = { ...a, [val]: { ...a[val], [c.iso]: c[val] } }
          }
        }
        return a
      }, {})
      acc = { ...acc, [current.key]: { image: current.imageFile, ...loc } }
    }
    if (current["__typename"] === "DataItemLinkGet") {
      let value = current.locales.reduce((a, c) => {
        for (let val in c) {
          if (val !== "iso") {
            a = { ...a, [val]: { ...a[val], [c.iso]: c[val] } }
          }
        }
        return a
      }, {})
      let slugs = current.slugs.reduce((a, c) => {
        return (a = { ...a, [c.iso]: c.value })
      }, {})
      acc = { ...acc, [current.key]: { ...value, slug: slugs } }
    }
    return acc
  }, {})
}

const getSectionsData = (sections) => {
  const sectionObject = getSections(sections)
  let sectionsData = {}
  for (let sec in sectionObject) {
    sectionsData = {
      ...sectionsData,
      [sec]:
        sectionObject[sec].items && sectionObject[sec].items[0]
          ? getItems(sectionObject[sec].items)
          : null,
    }
    if (sectionObject[sec].selectedModules?.length > 0) {
      sectionsData = {
        ...sectionsData,
        [sec]: {
          ...sectionsData[sec],
          selectedModules: sectionObject[sec].selectedModules,
        },
      }
    }
    if (sectionObject[sec].selectedPlugin) {
      sectionsData = {
        ...sectionsData,
        [sec]: {
          ...sectionsData[sec],
          selectedPlugin: sectionObject[sec].selectedPlugin,
        },
      }
    }
  }
  return sectionsData
}

const sortModulesAndAssets = (modules, order = "asc") => {
  if (modules.length > 0) {
    return modules.sort((a, b) => {
      if (a.position !== undefined && b.position !== undefined) {
        if (order === "asc") {
          return parseInt(a.position) - parseInt(b.position)
        } else {
          return parseInt(b.position) - parseInt(a.position)
        }
      }
      return 0
    })
  }
  return null
}

module.exports = {
  localizedValue,
  getSection,
  getSections,
  getItems,
  getSectionsData,
  isEmpty,
  findObjPropAndReplace,
  sortModulesAndAssets,
}
