// import React from "react"
import styled from "styled-components"

const SectionBox = styled.section`
  width: ${({ width }) => (width ? width : "85%")};
  margin: auto;
  padding: 30px 0;
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? paddingBottom : "30px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: ${({ borderTop }) => (borderTop ? borderTop : null)};
  border-bottom: ${({ borderBottom }) => (borderBottom ? borderBottom : null)};
  .title {
    font-size: calc(24px + (64 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
    text-align: center;
    color: var(--blue);
    margin: 15px 0;
  }
  .text {
    font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
    line-height: 29px;
    font-weight: 300;
    max-width: 755px;
    text-align: center;
    margin:15px 0;
    b {
      margin-bottom:20px;
      display:inline-block;
    }
  }
`

export default SectionBox
