// import React from "react"
import styled from "styled-components"

const SectionSeparator = styled.div`
  height: 45px;
  width: ${({ width }) => (width ? width : "1.5px")};
  background: ${({ bgColor }) => (bgColor ? bgColor : "black")};
  @media screen and (min-width: 990px) {
    height: 90px;
  }
`

export default SectionSeparator
