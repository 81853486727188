export const formatedText = (text) => {
  let makeHtmlTag = (someword) => {
    const tester = someword.substring(0, 3)
    let word = ""
    // console.log("WORD:", word)
    // console.log("TESTER: ", tester)
    switch (tester) {
      case "## ":
        word = someword.substring(3)
        return `<h2>${word.trim()}</h2>`
      case "** ":
        word = someword.substring(3)
        return `<strong>${word.trim()}</strong>`
      default:
        word = someword.trim()
        return `<p>${word}</p>`
    }
  }

  let splitedString = text.split(/\r?\n/)
  return splitedString
    .map((str) => {
      return makeHtmlTag(str)
    })
    .join("")
}
