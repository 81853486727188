import React, { useState } from "react"
import styled from "styled-components"
import Gallery from "react-grid-gallery"
import Button from "../Button"
import {FaFilePdf} from "react-icons/fa";

const SingleOffer = styled(
  ({
    className,
    additionalData,
    price,
    lang,
    children,
    text,
    tags,
    galleryImages,
  }) => {
    const imagesNew = galleryImages.map((gimage, index) => {
      return {
        src: gimage.publicURL,
        thumbnail: gimage.publicURL,
        thumbnailWidth: gimage.childImageSharp?.gatsbyImageData?.width,
        thumbnailHeight: gimage.childImageSharp?.gatsbyImageData?.height,
      }
    })
    const featuredImgs = [...imagesNew].splice(0, 6)
    const imagesLeftCount = [...imagesNew].splice(6).length
    // console.log("imagesLeftCount",imagesLeftCount)
    const [showingImages, setShowingImages] = useState(featuredImgs)
    const [showButton, setShowButton] = useState(true)

    // const formatedPrice = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumFractionDigits: '0' }).format(parseInt(price));

    const onButtonMorePhotosClick = (e) => {
      e.preventDefault()
      setShowingImages(imagesNew)
      setShowButton(false)
    }
    const onLightBoxClosing = (data) => {
      setShowingImages(featuredImgs)
      setShowButton(true)
    }
    const onLightBoxWillOpen = (data) => {
      setShowingImages(imagesNew)
    }
    const accomodationAD = additionalData.find(
      (ad) => ad.title === "accomodation"
    )
    const brochure = additionalData.find(
      (ad) => ad.title === "brochureUrl"
    )
    return (
      <div className={className}>
        <div className="text">
          <div dangerouslySetInnerHTML={{ __html: text }} />
          <h3>{accomodationAD.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: accomodationAD.content }} />
          {tags.tagsWithoutDescription.length > 0 && (
            <div>
              <h3>CONVENIENCES</h3>
              <ul className="tags-list">
                {tags.tagsWithoutDescription.map((twd) => {
                  return <li>{twd.title}</li>
                })}
              </ul>
            </div>
          )}
            {brochure && <a target="_blank" className="brochure" href={brochure.content}>Brochure <FaFilePdf size={20} color="#776d61" /></a> }
        </div>
        <div className="gallery">
          <Gallery
            lightboxWillOpen={onLightBoxWillOpen}
            lightboxWillClose={onLightBoxClosing}
            rowHeight={500}
            backdropClosesModal
            enableLightbox={true}
            margin={1}
            images={showingImages}
          />
          {imagesLeftCount > 0 && showButton && (
            <div className="flex">
              <Button
                onClick={onButtonMorePhotosClick}
              >{`+ ${imagesLeftCount} photos`}</Button>
            </div>
          )}
        </div>
      </div>
    )
  }
)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #f2f4f7;
  margin-top: 30px;
  .flex {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    justify-content: flex-end;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: normal;
    color: var(--grey);
  }
  .brochure {
    text-decoration: none;
    font-size:13px;
    color:#776d61;
    font-weight: bold;
    text-transform: uppercase;
  }
  .tags-list {
    list-style: none;
    padding: 0;
    text-transform: uppercase;
  }
  .text {
    width: 100%;
    @media screen and (min-width: 992px) {
      width: 50%;
    }
    @media screen and (min-width: 1200px) {
      width: 35%;
    }
    padding: 30px 25px;
    @media screen and (min-width: 768px) {
      padding: 30px 60px;
    }
    text-align: left;
    font-size: 16px;
    ul {
      list-style-type:initial;
      padding-left:40px;
      margin:30px 0;
    }
  }
  .gallery {
    position: relative;
    width: 100%;
    @media screen and (min-width: 992px) {
      width: 50%;
    }
    @media screen and (min-width: 1200px) {
      width: 65%;
    }
  }
`

export default SingleOffer
