import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SectionBannerImage from "../components/SectionBanner"
import SectionBox from "../components/SectionBox"
import { sortModulesAndAssets } from "../../plugins/berry-plugin/util/helpers"
import SingleOffer from "../components/OfferModule/SingleOffer"
import SectionSeparator from "../components/SectionHorizontalSeparator"
import { formatedText } from "../utils/helpers"
import TagBox from "../components/TagBox"
import ContactSection from "../components/ContactSection/ContactSection"
import ContactForm from "../components/ContactForm/ContactForm"
import GlobalContext from "../components/GlobalContext/GlobalContext"

const localText = {
  title: {
    sr: "Kontakt",
    en: "Contact",
  },
  formTitle: {
    sr: "Pošalji upit",
    en: "Send enquiry",
  },
  formSubtitle: {
    sr: "",
    en: "Please get in touch to discuss your needs and find right vessel for you.",
  },
}

const OfferTagBoxList = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  max-width: 1600px;
  margin: 50px auto;
`

const OfferSingle = ({ pageContext, location, data }) => {
  const { metaInfo, lang: pLang, slug, otherLang, defLang, templateData } = pageContext
  // console.log("TEMPLATE DATA: ", templateData)
  const { lang, dispatch } = useContext(GlobalContext)
  useEffect(() => {
    dispatch({ type: "changeLanguage", payload: pLang })
  }, [pLang,dispatch])
  const formTitle = templateData.sections[0].items[0].locales.find(t => t.iso === lang)?.value
  const formSubtitle = templateData.sections[0].items[1].locales.find(t => t.iso === lang)?.value
  const formButtonText = templateData.sections[0].items[2].locales.find(t => t.iso === lang)?.value

  const bannerImage = data.offer.imageFile.publicURL
  const { title, description, content, metaTitle, metaDescription } = data.offer.locales.find(
    (l) => l.iso === lang
  )
  const sortedAssets = sortModulesAndAssets(data.offer.gallery.galleryImages)
  const images = sortedAssets.map((item) => {
    return item.galleryImage
  })
  // console.log("SORTED IMAGES: ", images)
  const tagsWithoutDescription = data.offer.tags
    .filter((t) => {
      return t.locales.find((l) => l.iso === lang).description === ""
    })
    .map((twd) => {
      return {
        title: twd.locales.find((l) => l.iso === lang).title,
      }
    })
  const tagsWithDescription = data.offer.tags
    .filter((t) => {
      return t.locales.find((l) => l.iso === lang).description !== ""
    })
    .map((twd) => {
      return {
        title: twd.locales.find((l) => l.iso === lang).title,
        description: twd.locales.find((l) => l.iso === lang).description,
        image: twd.tagImage,
      }
    })
  // console.log("LANG: ", lang)
  // console.log("ADDITIONAL DATA: ", data.offer.additionalData)
  const additionalData = data.offer.additionalData.map((ad) => {
    return {
      title: ad.key,
      content: ad.locales.find((l) => l.iso === lang).value,
    }
  })
  return (
    <Layout
      location={location}
      metaInfo={{ metaTitle, metaDescription }}
      lang={lang}
      slug={slug}
      ohterLang={otherLang}
      defLang={defLang}
    >
      <SectionBannerImage
        noMargin
        title={title}
        topBanner
        bgImage={bannerImage}
        alt={`${title}`}
        description={description}
      />
      <SectionBox
        borderBottom="10px solid #ab9a87"
        paddingBottom="0px"
        width="100%"
      >
        <SectionSeparator width="2px" />
        <SingleOffer
          tags={{ tagsWithoutDescription, tagsWithDescription }}
          lang={lang}
          additionalData={additionalData}
          price={data.offer.priceFrom}
          text={content}
          galleryImages={images}
        />
        {tagsWithDescription.length > 0 && (
          <OfferTagBoxList>
            {tagsWithDescription.map((twd, index) => {
              const htmlText = formatedText(twd.description)
              return (
                <TagBox
                  orderIndex={index}
                  key={twd.title + index}
                  image={twd.image}
                  alt={twd.title}
                  htmlText={htmlText}
                />
              )
            })}
          </OfferTagBoxList>
        )}
      </SectionBox>
      <ContactSection
        title={formTitle || localText.formTitle[lang]}
        subtitle={formSubtitle || localText.formSubtitle[lang]}
      >
        <ContactForm subtitle={formSubtitle} btnLabel={formButtonText} withSubText lang={lang} />
      </ContactSection>
    </Layout>
  )
}

export const query = graphql`
  query ($uid: String!) {
    offer(uid: { eq: $uid }) {
      additionalData {
        key
        locales {
          iso
          value
        }
      }
      category {
        locales {
          iso
          title
        }
      }
      gallery {
        galleryImages {
          position
          galleryImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
            publicURL
          }
        }
      }
      imageFile {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
        publicURL
      }
      priceFrom
      tags {
        locales {
          iso
          description
          title
        }
        tagImage {
          childImageSharp {
            gatsbyImageData(formats: JPG)
          }
        }
      }
      locales {
        value
        title
        metaTitle
        metaKeywords
        metaDescription
        iso
        description
        content
      }
    }
  }
`

export default OfferSingle
