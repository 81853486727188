import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Button = styled(
  ({
    className,
    onClick,
    type,
    children,
    bgColor,
    textColor,
    linkTo,
    border,
    padding,
    width,
  }) => {
    // console.log("LINK TO: ", linkTo)
    return linkTo ? (
      <Link onClick={onClick} to={linkTo} className={className}>
        {children}
      </Link>
    ) : (
      <button type={type} onClick={onClick} className={className}>
        {children}
      </button>
    )
  }
)`
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ textColor }) => (textColor ? textColor : "white")};
  background: ${({ bgColor }) => (bgColor ? bgColor : "#001f63")};
  border: ${({ border }) => (border ? border : `1px solid #ab9a87`)};
  border-radius: 3px;
  font-size: 12px;
  //border:none;
  padding: ${({ padding }) => (padding ? padding : "15px 45px")};
  width: ${({ width }) => (width ? width : "250px")};
  text-align: center;
  a {
    text-decoration: none;
    color:inherit;
  }
  &:hover {
    cursor: pointer;
  }
  &:last-child {
    margin-left: ${({margin}) => margin ? margin : "30px"};
  }
`

export default Button
